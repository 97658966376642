<template>
  <div>
    <div class="col-lg-12 mx-auto">
      <b-row>
        <b-col lg="12">
          <h2>Meu Resultado</h2>
          <div class="w-100 border p-5">
            <perfil-admin :chardOptions="chartOptions" :userData="adminData" />
          </div>
        </b-col>
        <!-- <b-col lg="6">
          <h2>Média da equipe</h2>
          <div class="w-100 border p-5">
            <v-chart class="chart" :option="data" />
          </div>
        </b-col> -->
      </b-row>

    <vue-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="true"
    :paginate-elements-by-height="1000"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a4"
    pdf-orientation="landscape"
    pdf-content-width="100%"
    ref="html2Pdf">
      <section slot="pdf-content" v-if="printData.length">
        <dash-print :adminData="adminData" :printData="printData"/>
      </section>
    </vue-html2pdf>

      <b-row class="mt-5" v-if="usersCharts.length > 1">
        <b-col lg="12">
          <h2>Resultado da equipe</h2>
        </b-col>
        <b-col class="col-xxl-4 col-lg-6 mt-3" v-for="(data, index) in usersCharts" :key="index">
          <div class="w-100 rounded border p-3">
            <v-chart class="chart" :option="data" />
          </div>
        </b-col>
      </b-row>

      <div class="w-100 d-flex justify-content-center mt-5">
        <b-button variant="primary" @click="generateReport">
          Imprimir resultados
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import PerfilAdmin from '@/components/PerfilAdmin.vue';
import DashPrint from '@/components/DashPrint.vue';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    VChart,
    PerfilAdmin,
    DashPrint,
    VueHtml2pdf,
  },
  provide: {
    [THEME_KEY]: 'light',
  },
  props: {
    usersData: Array,
    printData: Array,
  },
  data() {
    return {
      usersCharts: [],
      adminData: {},
      usersMedia: {},
      print: false,
      chartOptions: {
        position: 'outside',
        fontSize: 14,
        fontColor: '#999',
        radius: 60,
      },
      optionsPdf: {
        margin: 5,
        filename: 'Perfil_Disc_Fazenda.pdf',
      },
    };
  },
  methods: {
    createChartUsers() {
      const adminData = this.usersData.find((item) => item.user_id === parseInt(this.$route.query.user_id, 10));
      const index = this.usersData.findIndex((item) => item.user_id === parseInt(this.$route.query.user_id, 10));
      let somaDominante = 0;
      let somaInfluente = 0;
      let somaEstabilidade = 0;
      let somaConformidade = 0;

      this.usersData.forEach((item) => {
        somaDominante += item.result.A;
        somaInfluente += item.result.B;
        somaEstabilidade += item.result.C;
        somaConformidade += item.result.D;
      });

      this.usersMedia = {
        result: {
          A: somaDominante / this.usersData.length,
          B: somaInfluente / this.usersData.length,
          C: somaEstabilidade / this.usersData.length,
          D: somaConformidade / this.usersData.length,
        },
        name: 'Média da fazenda',
      };

      this.adminData = adminData.result;
      this.usersData.splice(index, 1);

      this.usersData.unshift(this.usersMedia);

      this.usersData.forEach((item) => {
        this.usersCharts.push({
          silent: true,
          title: {
            text: item.name,
            left: 'center',
            textStyle: {
              fontSize: 14,
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: '{d}%',
          },
          legend: {
            silent: true,
            selectedMode: false,
            top: 'center',
            orient: 'vertical',
            left: 'left',
            formatter: (name) => {
              if (name === 'D') return `${name} = Dominante`;
              if (name === 'I') return `${name} = Influente`;
              if (name === 'S') return `${name} = Estabilidade`;
              if (name === 'C') return `${name} = Conformidade`;

              return name;
            },
          },
          series: [
            {
              name: 'Perfil',
              type: 'pie',
              radius: '60%',
              center: ['60%', '60%'],
              color: [
                'rgb(255 25 73 / 70%)',
                'rgb(255 210 0 / 70%)',
                'rgb(0 204 87 / 70%)',
                'rgb(0 186 235 / 70%)',
              ],
              data: [
                { value: item.result.A, name: 'D' },
                { value: item.result.B, name: 'I' },
                { value: item.result.C, name: 'S' },
                { value: item.result.D, name: 'C' },
              ],
              label: {
                fontSize: 13,
                formatter: '{b}: {d}%',
                color: '#999',
                fontWeight: 'bold',
              },
            },
          ],
        });
      });
    },

    generateReport() {
      this.print = true;
      this.$refs.html2Pdf.generatePdf();
    },

    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            /* eslint-disable-next-line */
            pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3));
          }
        });
    },
  },
  created() {
    this.createChartUsers();
  },
};
</script>
<style scoped>
  .chart {
    height: 25vh;
  }
  @page {
    @top-center {
    content: element(header,first-except);}
    @bottom-left {content: element(footer);}
    margin-top: 200px;
  }

</style>
