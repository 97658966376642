<template>
  <div id="print">
    <b-row class="py-5">
      <b-col lg="6">
        <v-chart v-if="userChart" class="chart" :option="userChart" />
      </b-col>
      <b-col lg="6" class="d-flex align-items-center position-relative" v-if="user_perfis.length > 0">
          <div class="w-100 p-4 maia-container shadow"
            v-show="perfis.a && user_perfis[0].key === 'a'"
          >
            <h3 class="text-red text-center">DOMINANTE</h3>
            <p class="py-3 text-center">
              Uma pessoa com esse perfil apresenta facilidade para lidar com desafios e dificuldades,
              tende a ser mais prática, direta, independente, egocêntrica, exigente, competitiva e assertiva.
              É também um perfil com maior facilidade para pressionar e assumir o controle. <br> <br>
              Feedbacks curtos e objetivos são a principal forma de direcionar um perfil de dominância.
              Diferente de outros perfis, o vermelho não foca na sociabilidade
              e descontração então não se prenda a isso, seja direto e preciso, pois, para ele tempo é tudo.
            </p>
            <div class="d-flex justify-content-center">
              <button class="bt" @click="user_perfis[1].key === 'a' ? changePerfil('f') : changePerfil()">
                Próximo <i class="fas fa-arrow-right"></i>
              </button>
            </div>
            <img src="@/assets/maia.png" class="maia" alt="">
          </div>

          <div class="w-100 p-4 maia-container shadow"
            v-show="perfis.b && user_perfis[0].key === 'b'"
          >
            <h3 class="text-yellow text-center">INFLUENTE</h3>
            <p class="py-3 text-center">
              A pessoa costuma ser extrovertida e sociável. Por gostar de interagir,
              pode apresentar também uma influência espontânea sobre as outras pessoas,
              enquanto se preocupa também com o que os outros pensam dela. <br> <br>
              Algumas características predominantes são o entusiasmo, autoconfiança,
              capacidade de se comunicar, criatividade, otimismo, poder de persuasão,
              impulsividade, empatia, além de preferir ambientes de trabalho em equipe.
            </p>
            <div class="d-flex justify-content-center">
              <button class="bt" @click="user_perfis[1].key === 'b' ? changePerfil('f') : changePerfil()">
                Próximo <i class="fas fa-arrow-right"></i>
              </button>
            </div>
            <img src="@/assets/maia.png" class="maia" alt="">
          </div>

          <div class="w-100 p-4 maia-container shadow"
            v-show="perfis.c && user_perfis[0].key === 'c'"
          >
            <h3 class="text-green text-center">ESTABILIDADE</h3>
            <p class="py-3 text-center">
              Costuma ser uma pessoa mais reservada, mas mantém bons relacionamentos de trabalho ao ajudar e apoiar os colegas,
              além de ter facilidade para seguir rotinas e processos padronizados. <br> <br>
             As características desse perfil são lidas a estabilidade, como lealdade, escuta ativa, diplomacia, paciência e gentileza.
             É por isso que são pessoas que apresentam capacidade para lidar com mudanças, adotando uma postura resiliente.
            </p>
            <div class="d-flex justify-content-center">
              <button class="bt" @click="user_perfis[1].key === 'c' ? changePerfil('f') : changePerfil()">
                Próximo <i class="fas fa-arrow-right"></i>
              </button>
            </div>
            <img src="@/assets/maia.png" class="maia" alt="">
          </div>

          <div class="w-100 p-4 maia-container shadow"
            v-show="perfis.d && user_perfis[0].key === 'd'"
          >
            <h3 class="text-blue text-center">CONFORMIDADE</h3>
            <p class="py-3 text-center">
              Possui maior facilidade para respeitar ordens e decisões tomadas por terceiros.
              E por isso, pessoas com esse modelo de comportamento preferem ambientes com processos bem definidos.Por outro lado,
              a conformidade influencia características analíticas como cautela, disciplina, rigor, busca pela exatidão. <br><br>
              Com essa personalidade, o indivíduo tem mais facilidade para cumprir prazos e manter a qualidade das entregas.
            </p>
            <div class="d-flex justify-content-center">
              <button class="bt" @click="user_perfis[1].key === 'd' ? changePerfil('f') : changePerfil()">
                Próximo <i class="fas fa-arrow-right"></i>
              </button>
            </div>
            <img src="@/assets/maia.png" class="maia" alt="">
          </div>

          <div class="w-100 p-4 maia-container shadow"
            v-show="user_perfis[0].key === 'f'"
          >
            <h3 class="text-green text-center">QUER SABER MAIS? </h3>
            <p class="py-3 text-center">
              Para saber mais detalhes a respeito dos perfis DISC,faça o download do nosso material clicando no botão abaixo:
            </p>
            <div class="d-flex justify-content-center">
              <a :href="link_pdf" class="nav-link">
                <button class="bt">Baixar</button>
              </a>
            </div>
            <img src="@/assets/maia.png" class="maia" alt="">
          </div>
        </b-col>
    </b-row>
  </div>
</template>
<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: {
    userData: {
      required: true,
    },
    print: Boolean,
    chardOptions: {
      type: Object,
      require: true,
    },
  },
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: 'light',
  },
  data() {
    return {
      link_pdf: '',
      user_perfis: [],
      perfis: {
        a: false,
        b: false,
        c: false,
        d: false,
      },
      userChart: {
        silent: true,
        title: {
          text: '',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{d}%',
        },
        series: [
          {
            name: 'Perfil',
            type: 'pie',
            radius: `${this.chardOptions.radius}%`,
            center: ['50%', '50%'],
            color: [
              'rgb(255 25 73 / 70%)',
              'rgb(255 210 0 / 70%)',
              'rgb(0 204 87 / 70%)',
              'rgb(0 186 235 / 70%)',
            ],
            label: {
              position: this.chardOptions.position,
              fontSize: this.chardOptions.fontSize,
              formatter: '{b}: {d}%',
              color: this.chardOptions.fontColor,
              fontWeight: 'bold',
            },
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    changePerfil(perfil = '') {
      if (this.user_perfis[0].key !== this.user_perfis[1].key) {
        this.user_perfis[0].key = this.user_perfis[1].key;
      } else {
        this.user_perfis[0].key = perfil;
      }
    },

    showPerfis() {
      let orderData = [];
      Object.keys(this.userData).forEach((key) => {
        orderData.push({
          result: this.userData[key],
          key: key.toLowerCase(),
        });
      });

      this.userChart.series[0].data.push(
        { value: this.userData.A, name: 'Dominante' },
        { value: this.userData.B, name: 'Influente' },
        { value: this.userData.C, name: 'Estabilidade' },
        { value: this.userData.D, name: 'Conformidade' },
      );

      orderData = this._.orderBy(orderData, 'result', 'desc');
      this.user_perfis = orderData.splice(0, 2);

      this.user_perfis.forEach((item) => {
        if (item.key === 'a') this.perfis.a = true;
        if (item.key === 'b') this.perfis.b = true;
        if (item.key === 'c') this.perfis.c = true;
        if (item.key === 'd') this.perfis.d = true;
      });
    },
  },
  watch: {
    print(newValue) {
      return newValue;
    },
  },
  created() {
    const route = this.$route.query;
    this.showPerfis();
    this.link_pdf = `${process.env.VUE_APP_PDF_LINK}?origem=${route.origem}&user_id=${route.user_id}&safra_id=${route.safra_id}`;
  },
};
</script>
<style scoped>
  .chart {
    height: 50vh;
  }
  .bt-container {
    position: absolute;
    bottom: 200px;
    left: 301px;
    background: #fff;
  }

  .bt {
    padding: 5px 30px;
    background: #d97d3e;
    border-radius: 10px;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bt i {
    margin-left: 10px;
  }

  .text-red {
    color: rgb(255 25 73 / 70%);
    font-weight: bold;
  }

  .text-yellow {
    color: rgb(255 210 0 / 70%);
    font-weight: bold;
  }

  .text-green {
    color: rgb(0 204 87 / 70%);
    font-weight: bold;
  }

  .text-blue {
    color: rgb(0 186 235 / 70%);
    font-weight: bold;
  }

  .maia {
    width: 100px;
    position: absolute;
    right: 0;
    bottom: -130px;
  }

  .maia-container{
    background: #fff;
    border-radius: 70px;
    position: relative;
    border: 1px solid #eee;
    box-shadow: 5px 1rem 1rem rgb(0 0 0 / 15%) !important;
  }

   .maia-container::before{
    border: 20px solid transparent;
    border-top: 20px solid #fff;
    border-bottom: 0;
    height: 0;
    width: 0;
    border-top-width: 40px;
    content: "";
    display: block;
    position: absolute;
    left: 80%;
    bottom: -35px;
    transform-origin: center;
    transform: rotate(90deg) skew(-25deg) translateY(16.6666666667px);
  }
</style>
