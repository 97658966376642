/* eslint-disable class-methods-use-this */
import http from '@/http';
import Service from './Service';

class QuestionService extends Service {
  constructor() {
    super('questions');
  }

  getResult(payload) {
    return http.post('questions/result', payload);
  }
}

const questionService = new QuestionService();
export default questionService;
