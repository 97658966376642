<template>
  <div class='home' id="print">
    <div id="modal-info" v-if="show_modal">
      <div class="col-lg-4 p-4 maia-container shadow">
          <h3 class="text-green text-center">O QUE É DISC?</h3>
          <p class="py-3 text-center">
            O teste DISC é um método de avaliação psicológica, criada para identificar e mapear traços do perfil comportamental de uma pessoa.
            <br>
            Com base em informações sobre seu modo de viver, pensar e agir, a teoria DISC consegue classificar a existência dos quatro tipos de
            comportamento predominantes.
          </p>
          <div class="d-flex justify-content-center">
            <button class="bt" @click="() => { this.show_modal = false; }">
              Responder questionário
            </button>
          </div>
          <img src="@/assets/maia.png" class="maia" alt="">
        </div>
    </div>
    <div class="col-lg-10 mx-auto">
      <div class='w-100 text-center'>
        <img src='@/assets/disc-2.png' id='logo' alt='' />
        <h1>Análise de perfil DISC</h1>
      </div>
      <form-wizard
      shape="circle"
      color='#49a95a'
      ref='wizard'
      title=''
      v-if='questions.length > 0 && !finish'
      :start-index='index'
      subtitle=''>
        <tab-content v-for='(item, index) in questions' :key='item.id' class='text-left'>
          <div class='w-100 py-3'>
            <h4>{{ item.title }}:</h4>
            <div class='form-check' v-for='answer in item.answers' :key='answer.id'>
              <input
                @click='save(answer)'
                class='form-check-input'
                type='radio'
                :name='answer.title'
                :value='answer.value'
                :id='answer.title'
                v-model='user_answers[index].value'
              />
              <label class='form-check-label' :for='answer.title'>
                  {{ answer.title }}
              </label>
            </div>
          </div>
        </tab-content>
        <template slot='footer' slot-scope='props'>
          <div class='wizard-footer-left'>
            <wizard-button
              v-if='props.activeTabIndex > 0 && !props.isLastStep'
              @click.native='props.prevTab()'
              :style='props.fillButtonStyle'
              ><i class="fas fa-arrow-left"></i> Voltar</wizard-button
            >
          </div>
          <div class='wizard-footer-right'>
            <wizard-button
              :style='props.fillButtonStyle'
              class='wizard-footer-right'
              v-if='props.isLastStep && question === 1 && show_next'
              @click.native='changeModule'
              >
              Próximo módulo <i class="fas fa-arrow-right"></i>
            </wizard-button>

            <wizard-button
              :style='props.fillButtonStyle'
              class='wizard-footer-right'
              v-if='props.isLastStep && question === 2 && show_next'
              @click.native='getResult'
              >
              <i class="fas fa-clipboard-list"></i> Visualizar resultado
            </wizard-button>
          </div>
          <!-- <div class='wizard-footer-right'>
              <wizard-button
              v-if='!props.isLastStep'
              @click.native='props.nextTab()'
              class='wizard-footer-right'
              :style='props.fillButtonStyle'>Pro</wizard-button>

            <wizard-button
          v-else @click.native='alert('Done')'
          class='wizard-footer-right finish-button'
          :style='props.fillButtonStyle'>  {{props.isLastStep ? 'Done' : 'Next'}}</wizard-button>
        </div> -->
        </template>
      </form-wizard>

      <div class='w-100 border rounded p-5 mt-5' v-if="finish && admin === 'false'">
        <h1 class="text-center">Resultado</h1>
        <perfil-admin v-if="load" :chardOptions="chartOptions" :userData="userData"/>
      </div>
    </div>
    <div class="col-lg-10 mx-auto">
      <b-row class="py-5" v-show="finish && !load">
        <b-col lg="12">
          <b-skeleton-img animation="throb" no-aspect height="600px"></b-skeleton-img>
        </b-col>
      </b-row>
    </div>
    <dash-admin v-if="users_data.length > 0 && admin === 'true'" :printData="printData" :usersData="users_data"/>
  </div>
</template>

<script>
import QuestionService from '@/services/QuestionService';
import DashAdmin from '@/components/DashAdmin.vue';
import PerfilAdmin from '@/components/PerfilAdmin.vue';

export default {
  name: 'Home',
  components: {
    DashAdmin,
    PerfilAdmin,
  },
  data() {
    return {
      admin: this.$route.query.admin,
      questions: [],
      user_answers: [],
      show_next: false,
      index: 0,
      select: '',
      test: 'A',
      group: 'group_1',
      question: 1,
      finish: false,
      load: false,
      user_perfis: [],
      show_modal: false,
      printData: [],
      users_data: {},
      link_pdf: '',
      userData: [],
      chartOptions: {
        position: 'inner',
        fontSIze: 14,
        fontColor: '#fff',
        radius: 100,
      },
    };
  },
  methods: {
    async getList() {
      const params = {
        ...this.$route.query,
      };

      const { data } = await QuestionService.getList(params);

      if (data.answers.length === 0) this.show_modal = true;

      this.index = data.answers.filter((it) => it.answer.question_id === this.question).length;

      if (data.answers.length === 50) {
        this.finish = true;
        setTimeout(() => {
          this.getResult();
        }, 2000);
      }

      if (this.index > 24) {
        this.question = 2;
        this.questions = [];
        this.user_answers = [];
        this.group = 'group_2';

        this.index = data.answers.filter((it) => it.answer.question_id === this.question).length;
      }

      const questions = this._.groupBy(data[this.group], 'indice');

      Object.keys(questions).forEach((item) => {
        this.questions.push({
          title: questions[item][0].question.title,
          answers: questions[item],
          indice: questions[item][0].indice,
        });
      });

      // console.log(this.questions);

      this.questions.forEach((item) => {
        const answer = {
          value: '',
        };
        data.answers.forEach((el) => {
          if (item.indice === el.indice && el.answer.question_id === this.question) {
            answer.value = el.answer ? el.answer.value : '';
          }
        });

        this.user_answers.push(answer);
      });
    },

    async getResult() {
      const payload = {
        ...this.$route.query,
      };

      const { data } = await QuestionService.getResult(payload);

      this.users_data = data.users;
      this.printData = data.users;

      this.userData = data.group_1;

      this.finish = true;

      this.load = true;
    },

    changePerfil(perfil = '') {
      if (this.user_perfis[0].key !== this.user_perfis[1].key) {
        this.user_perfis[0].key = this.user_perfis[1].key;
      } else {
        this.user_perfis[0].key = perfil;
      }
    },

    changeModule() {
      this.questions = [];
      this.user_answers = [];
      this.group = 'group_2';
      this.question = 2;
      this.getList();
    },

    async save(answer) {
      const payload = {
        ...this.$route.query,
        answer_id: answer.id,
        indice: answer.indice,
        question_id: answer.question_id,
      };

      if (answer.indice === 25) {
        this.show_next = true;
      } else {
        this.show_next = false;
      }

      try {
        await QuestionService.save(payload);
        this.$refs.wizard.nextTab();
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style scoped>
  .chart {
    height: 70vh;
  }

  #questions {
    width: 1300px;
    margin: auto;
  }

  .bt-container {
    position: absolute;
    bottom: 200px;
    left: 301px;
    background: #fff;
  }

  .bt {
    padding: 5px 30px;
    background: #d97d3e;
    border-radius: 10px;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bt i {
    margin-left: 10px;
  }

  .text-red {
    color: rgb(255 25 73 / 70%);
    font-weight: bold;
  }

  .text-yellow {
    color: rgb(255 210 0 / 70%);
    font-weight: bold;
  }

  .text-green {
    color: rgb(0 204 87 / 70%);
    font-weight: bold;
  }

  .text-blue {
    color: rgb(0 186 235 / 70%);
    font-weight: bold;
  }

  .maia-container{
    background: #fff;
    border-radius: 70px;
    position: relative;
    border: 1px solid #eee;
    box-shadow: 5px 1rem 1rem rgb(0 0 0 / 15%) !important;
  }

  .maia-container::before{
    border: 20px solid transparent;
    border-top: 20px solid #fff;
    border-bottom: 0;
    height: 0;
    width: 0;
    border-top-width: 40px;
    content: "";
    display: block;
    position: absolute;
    left: 80%;
    bottom: -35px;
    transform-origin: center;
    transform: rotate(90deg) skew(-25deg) translateY(16.6666666667px);
  }

  .maia {
    width: 100px;
    position: absolute;
    right: 0;
    bottom: -130px;
  }

  .vue-form-wizard .wizard-tab-content {
    display: flex;
    justify-content: center;
  }

  #modal-info {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000b8;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
