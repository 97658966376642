import axios from 'axios';
// eslint-disable-next-line import/no-cycle
// import LoginService from '@/Services/LoginService';
// eslint-disable-next-line import/no-cycle
// import router from './router';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API,
});

axiosInstance.interceptors.request.use((config) => {
  if (config && config.url === '/login') {
    return config;
  }

  // const token = LoginService.getToken();
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${process.env.VUE_APP_TOKEN}`;

  return config;
}, (error) => {
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  return Promise.reject(error);
});

axiosInstance.interceptors.response.use((success) => success, (err) => {
  const { status } = err.response;
  const unauthorized = status === 401;
  if (unauthorized) {
    localStorage.clear();
  }
});

export default axiosInstance;
