import Vue from 'vue';
import VueFormWizard from 'vue-form-wizard';
import BootstrapVue from 'bootstrap-vue';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;
Vue.use(VueFormWizard);
Vue.use(BootstrapVue);
Vue.use(VueLodash, { name: 'custom', lodash });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
