<template>
  <div>
    <div class="col-lg-12 mx-auto">
      <b-row>
        <b-col lg="12">
          <h2 class="text-center mt-5">Meu Resultado</h2>
          <div class="w-100 p-5">
            <perfil-admin-print :chardOptions="chartOptions" :userData="adminData" />
          </div>
        </b-col>
        <!-- <b-col lg="6">
          <h2>Média da equipe</h2>
          <div class="w-100 border p-5">
            <v-chart class="chart" :option="data" />
          </div>
        </b-col> -->
      </b-row>
    <div class="html2pdf__page-break"/>
      <b-row class="mt-5" v-if="usersCharts.length > 0">
        <b-col lg="12">
          <h2 class="text-center">Resultado da equipe</h2>
        </b-col>
        <b-col lg="6" class="mt-5" v-for="(data, index) in usersCharts" :key="index">
          <div class="w-100 p-3">
            <v-chart class="chart" :option="data" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import PerfilAdminPrint from '@/components/PerfilAdminPrint.vue';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    VChart,
    PerfilAdminPrint,
  },
  provide: {
    [THEME_KEY]: 'light',
  },
  props: {
    printData: Array,
    adminData: {
      required: true,
    },
  },
  data() {
    return {
      usersCharts: [],
      chartOptions: {
        position: 'outside',
        fontSize: 14,
        fontColor: '#999',
        radius: 60,
      },
    };
  },
  methods: {
    createChartUsers() {
      this.printData.forEach((item) => {
        this.usersCharts.push({
          silent: true,
          title: {
            text: item.name,
            left: 'left',
            textStyle: {
              fontSize: 14,
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: '{d}%',
          },
          legend: {
            silent: true,
            selectedMode: false,
            top: 'center',
            orient: 'vertical',
            left: 'left',
            formatter: (name) => {
              if (name === 'D') return `${name} = Dominante`;
              if (name === 'I') return `${name} = Influente`;
              if (name === 'S') return `${name} = Estabilidade`;
              if (name === 'C') return `${name} = Conformidade`;

              return name;
            },
          },
          series: [
            {
              name: 'Perfil',
              type: 'pie',
              radius: '60%',
              center: ['40%', '50%'],
              color: [
                'rgb(255 25 73 / 70%)',
                'rgb(255 210 0 / 70%)',
                'rgb(0 204 87 / 70%)',
                'rgb(0 186 235 / 70%)',
              ],
              data: [
                { value: item.result.A, name: 'D' },
                { value: item.result.B, name: 'I' },
                { value: item.result.C, name: 'S' },
                { value: item.result.D, name: 'C' },
              ],
              label: {
                fontSize: 13,
                formatter: '{b}: {d}%',
                color: '#999',
                fontWeight: 'bold',
              },
            },
          ],
        });
      });
    },
  },
  created() {
    this.createChartUsers();
  },
};
</script>
<style scoped>
  .chart {
    height: 25vh;
  }
</style>
